import React, { useState, useRef } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import './categories.css'; 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const ProductPage = ({sortBy}) => {


  const categories = [
    'Prata',
    'Dosai',
    'Breakfast',
    'Lunch Menu',
    'Main Courses',
    'Goreng',
    'Drinks',
  ];

// const allProducts = [
//   {
//     category: 'Breakfast',
//     products: [
//         { id: 1,  name: 'Butter Toast ( 4 Pc )', price: '59', offer: '40', org: '100', type: 'veg' , img:'https://lh3.googleusercontent.com/T-y829XBOE8h308lHJaYaQdzzRX1RErizHroPvvBAGdFxybVyjusZAlTrCWxUt1yXqfA67DmrlpegAYdcGTxWv-M_xJD7YVQQw1exj4=w512-rw' },
//         { id: 2,  name: 'Aloo Paratha (1 Pc)', price: '49', offer: '40', org: '100', type: 'veg' , img:'https://lh3.googleusercontent.com/ixXPhRZ6Ce4_oQST2r15wT4jvzjPkX5HDTED7YaJJ_7OFFU0QFzJuV-_s19cwEZs1X7gi4MejWf3gaceF3mxMe_jCiMUxOZhdnX8AfTE=w512-rw' },
//         { id: 3,  name: 'Paneer Paratha (1 Pc)', price: '59', offer: '40', org: '100', type: 'veg' , img:'https://www.viniscookbook.com/wp-content/uploads/2018/09/20180924_224755.jpg' },
//         { id: 4,  name: 'Pyaaz Pakoda (8 Pc)', price: '69', offer: '40', org: '100', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2021/07/onion-pakoda-pakora-500x375.jpg' },
//         { id: 5,  name: 'Paneer Pakoda ( 6 Pc)', price: '89', offer: '40', org: '100', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2016/06/paneer-pakoda.jpg' },
//         { id: 6,  name: 'Veg Cutlet ( 4 Pc )', price: '89', offer: '40', org: '100', type: 'veg' , img:'https://cdn.cdnparenting.com/articles/2020/03/07132833/328074536.webp' },
//         { id: 7,  name: 'Poha', price: '59', offer: '59', org: '100', type: 'veg' , img:'https://media.istockphoto.com/id/670526200/photo/poha-indian-snacks.jpg?s=612x612&w=0&k=20&c=iEVKSfRzmcHxPIz1fKQBNzmcRpLbseh5vjXaRVckRZA=' },
//         { id: 8,  name: 'Poori Bhaji (4 Pc)', price: '69', offer: '40', org: '100', type: 'veg' , img:'https://cdn.cdnparenting.com/articles/2020/02/26144721/PURI-BHAJI-RECIPE.webp' },
//         { id: 9,  name: 'Pav Bhaji ( 4 Pc )', price: '79', offer: '40', org: '100', type: 'veg' , img:'https://e1.pxfuel.com/desktop-wallpaper/196/259/desktop-wallpaper-17-best-pav-bhaji-places-in-mumbai-in-curlytales.jpg' },
//         { id: 10, name: 'Omelette ( 2 Pc )', price: '49', offer: '40', org: '100', type: 'non-veg' , img:'https://w0.peakpx.com/wallpaper/619/437/HD-wallpaper-food-omelette.jpg' },
//         { id: 11, name: 'Bread Omelette ( 2 Pc )', price: '69', offer: '40', org: '100', type: 'non-veg' , img:'https://i.pinimg.com/736x/27/27/fb/2727fbef997cedbd2aa3e1e4c576f32d.jpg' },
//         { id: 12, name: 'Half Fry Omelette ( 2 Pc )', price: '49', offer: '40', org: '100', type: 'non-veg' , img:'https://i.ytimg.com/vi/knXqJcpqaCU/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLBwiIKjWHjdA3BDvpqc8jnWJhqxhA' },
//         { id: 13, name: 'Maggi', price: '29', offer: '40', org: '100', type: 'veg' , img:'https://i.pinimg.com/originals/71/d6/db/71d6db959ed3f8e47e7ef8ccbf74ea7f.jpg' },
//         { id: 14, name: 'Vegitable Maggi', price: '39', offer: '40', org: '100', type: 'veg' , img:'https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=1080/assets/search/usecase/vegetable_maggi_zdish.png' },
//         { id: 15, name: 'Egg Maggi', price: '49', offer: '40', org: '100', type: 'non-veg' , img:'https://www.licious.in/blog/wp-content/uploads/2020/12/Egg-Maggi.jpg' },
     
//     ],
//   },
  
// ];


const allProducts = [
  {
    "category": "Prata",
    "products": [
      {"id": 1, "name": "Plain Prata", "price": "$1.00", "offer": "10%", "org": "$1.10", "type": "veg", "img": "https://media.istockphoto.com/id/618764348/photo/famous-asian-flat-bread-known-as-parathas.jpg?s=612x612&w=0&k=20&c=yrz3Gn1RIHw8ohxG0uGNAU1H8wa2dB6xRli_DD3PJ6o="},
      {"id": 2, "name": "Egg Prata", "price": "$1.50", "offer": "15%", "org": "$1.80", "type": "non-veg", "img": "https://i.ytimg.com/vi/Xz31ahbF9FA/maxresdefault.jpg"},
      {"id": 3, "name": "Egg Onion Prata", "price": "$2.50", "offer": "5%", "org": "$2.60", "type": "non-veg", "img": "https://hajikadirfoodchains.sg/image/cache/catalog/Thosai/Egg%20Onion-500x500.jpg"},
      {"id": 4, "name": "Onion Prata", "price": "$1.50", "offer": "12%", "org": "$1.70", "type": "veg", "img": "https://media.istockphoto.com/id/520961869/photo/vegetable-paratha-1.jpg?s=612x612&w=0&k=20&c=t4h_E3Gm8631Dng6SkXWLxPFsG-65BtKxPEOSwWx0NA="},
      {"id": 5, "name": "Cheese Prata", "price": "$3.00", "offer": "8%", "org": "$3.25", "type": "veg", "img": "https://previews.123rf.com/images/dourleak/dourleak1506/dourleak150600099/40787230-roti-with-cheese-on-white-plate-roti-prata.jpg"},
      {"id": 6, "name": "Mushroom Prata", "price": "$3.00", "offer": "10%", "org": "$3.30", "type": "veg", "img": "https://knsrestaurant.com/wp-content/uploads/2020/04/Mushroom-Prata.jpg"},
      {"id": 7, "name": "Hot Dog Prata", "price": "$3.00", "offer": "7%", "org": "$3.25", "type": "non-veg", "img": "https://media-cdn.tripadvisor.com/media/photo-s/0e/de/1e/fd/paratha-sausage-hotdog.jpg"},
      {"id": 8, "name": "Veg Kothu Prata", "price": "$5.00", "offer": "12%", "org": "$5.70", "type": "veg", "img": "https://www.flavourstreat.com/wp-content/uploads/2021/01/kothu-parotta-03-922x1024.jpg"},
      {"id": 9, "name": "Egg Kothu Prata", "price": "$5.50", "offer": "20%", "org": "$6.90", "type": "non-veg", "img": "https://www.shutterstock.com/image-photo/chicken-kothu-parotta-curried-shredded-260nw-2017150889.jpg"},
      {"id": 10, "name": "Chicken Kothu Prata", "price": "$6.50", "offer": "15%", "org": "$7.65", "type": "non-veg", "img": "https://www.relishthebite.com/wp-content/uploads/2015/05/Kothuparotta9.jpg"},
      {"id": 11, "name": "Mutton Kothu Prata", "price": "$7.50", "offer": "10%", "org": "$8.35", "type": "non-veg", "img": "https://media-cdn.tripadvisor.com/media/photo-s/1a/b5/d6/7e/mutton-kothu-parota.jpg"},
      {"id": 12, "name": "Masala Prata", "price": "$3.00", "offer": "18%", "org": "$3.65", "type": "veg", "img": "https://lh3.googleusercontent.com/proxy/0jkLMwWQAeRZGNA0f9OVCqfExeewu8hD35jqG3iq-fxOVESaLBpJDx3iYM72zhU2DkzvsQKdyHMVkJsTyKlnMZtvt2lGJacFAXgSgq-m"},
      {"id": 13, "name": "Chicken Murtabak", "price": "$7.00", "offer": "10%", "org": "$7.80", "type": "non-veg", "img": "https://romandianmasala.com/wp-content/uploads/2022/04/DSC01715-3-1200x675.jpg"},
      {"id": 14, "name": "Mutton Murtabak", "price": "$8.00", "offer": "15%", "org": "$9.40", "type": "non-veg", "img": "https://3.imimg.com/data3/XW/CI/MY-2752633/mutton-murtabak-500x500.jpg"},
      {"id": 15, "name": "Sardin Murtabak", "price": "$6.50", "offer": "12%", "org": "$7.40", "type": "non-veg", "img": "https://2.bp.blogspot.com/-jn1mVMEb0zM/V1Z4jfCCO_I/AAAAAAAADp4/vV1t-Chufp4JT_2BF6DTg3qBdwpqIIZfwCKgB/s1600/6.jpg"},
      {"id": 16, "name": "Tissue Prata", "price": "$3.00", "offer": "5%", "org": "$3.15", "type": "veg", "img": "https://www.shutterstock.com/image-photo/roti-tissue-tisu-prata-sweet-260nw-796900294.jpg"},
      {"id": 17, "name": "Egg Cheese Prata", "price": "$4.00", "offer": "10%", "org": "$4.45", "type": "non-veg", "img": "https://knsrestaurant.com/wp-content/uploads/2020/04/Egg-Cheese-Prata.jpg"},
      {"id": 18, "name": "Mushroom Cheese Prata", "price": "$4.50", "offer": "8%", "org": "$4.90", "type": "veg", "img": "https://burpple.imgix.net/foods/3baf488aadb585834121281169_original.?w=645&dpr=1&fit=crop&q=80&auto=format"},
      {"id": 19, "name": "Banana Prata", "price": "$3.00", "offer": "15%", "org": "$3.55", "type": "veg", "img": "https://burpple.imgix.net/foods/3baf488aadb585834121281169_original.?w=645&dpr=1&fit=crop&q=80&auto=format"},
      {"id": 20, "name": "Roti Bom", "price": "$2.50", "offer": "12%", "org": "$2.85", "type": "veg", "img": "https://my.fnlife.com/pub/media/amasty/blog/cache/W/e/800/800/Webp.net-resizeimage_-_2021-11-18T183827.946.jpg"}
    ]
  },
  {
    "category": "Dosai",
    "products": [
      {"id": 21, "name": "Plain Dosai", "price": "$1.80", "offer": "10%", "org": "$1.95", "type": "veg", "img": "https://www.shutterstock.com/image-photo/chicken-kothu-parotta-curried-shredded-260nw-2017150889.jpg"},
      {"id": 22, "name": "Onion Dosai", "price": "$2.50", "offer": "8%", "org": "$2.70", "type": "veg", "img": "https://rakskitchen.net/wp-content/uploads/2013/03/8527219504_0ddb2cde6f_z-500x375.jpg"},
      {"id": 23, "name": "Egg Dosai", "price": "$2.80", "offer": "15%", "org": "$3.30", "type": "non-veg", "img": "https://kitchenmai.com/wp-content/uploads/2022/01/egg_dosa_blog-500x500.jpg"},
      {"id": 24, "name": "Cheese Dosai", "price": "$3.50", "offer": "12%", "org": "$3.95", "type": "veg", "img": "https://www.shutterstock.com/image-photo/masala-dosa-cheese-uttapa-south-600nw-1393498667.jpg"},
      {"id": 25, "name": "Ghee Dosai", "price": "$3.00", "offer": "20%", "org": "$3.75", "type": "veg", "img": "https://www.tarladalal.com/members/9306/procstepimgs/ghee-roast-dosa-13-13-190104.jpg"},
      {"id": 26, "name": "Paper Dosai", "price": "$3.00", "offer": "10%", "org": "$3.35", "type": "veg", "img": "https://www.yummyfoodrecipes.in/resources/picture/org/Crispy-Paper-Dosa.jpg"},
      {"id": 27, "name": "Masala Dosai", "price": "$2.50", "offer": "15%", "org": "$2.95", "type": "veg", "img": "https://t4.ftcdn.net/jpg/01/89/45/21/360_F_189452136_gJBG4ZRXY9NnZZCGV2s8QhObmpeerJTO.jpg"},
      {"id": 28, "name": "Mushroom Dosai", "price": "$4.00", "offer": "8%", "org": "$4.35", "type": "veg", "img": "https://www.awesomecuisine.com/wp-content/uploads/2015/10/mushroom_dosa_recipe-480x270.jpg"},
      {"id": 29, "name": "Chicken Dosai", "price": "$6.00", "offer": "12%", "org": "$6.80", "type": "non-veg", "img": "https://catering.appansbiriyani.com/wp-content/uploads/2022/06/Chicken-Dosai.jpg"},
      {"id": 30, "name": "Mutton Dosai", "price": "$7.00", "offer": "10%", "org": "$7.75", "type": "non-veg", "img": "https://www.yummytummyaarthi.com/wp-content/uploads/2015/06/1-32.jpg"},
      {"id": 31, "name": "Sardin Dosai", "price": "$6.00", "offer": "18%", "org": "$6.90", "type": "non-veg", "img": "https://adm.sakunthalas.com//Dynamic/Products/114/Images/G21.SARDINETHOSAI.jpg"},
      {"id": 32, "name": "Rava Dosai", "price": "$3.00", "offer": "15%", "org": "$3.50", "type": "veg", "img": "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/05/rava-dosa-recipe.jpg"},
      {"id": 33, "name": "Onion Rava Dosai", "price": "$3.50", "offer": "10%", "org": "$3.85", "type": "veg", "img": "https://rakskitchen.net/wp-content/uploads/2012/05/7285957980_0ac3bc2bb4_z-500x500.jpg"},
      {"id": 34, "name": "Egg Onion Rava Dosai", "price": "$3.80", "offer": "8%", "org": "$4.15", "type": "non-veg", "img": "https://www.yummytummyaarthi.com/wp-content/uploads/2012/03/1-1.jpg"},
      {"id": 35, "name": "Ghee Rava Dosai", "price": "$3.50", "offer": "12%", "org": "$3.95", "type": "veg", "img": "https://www.vegrecipesofindia.com/wp-content/uploads/2019/03/rava-dosa-recipe-1a.jpg"},
      {"id": 36, "name": "Podi Dosai", "price": "$2.50", "offer": "15%", "org": "$2.95", "type": "veg", "img": "https://img-global.cpcdn.com/recipes/6c72ede2b4051e8d/1200x630cq70/photo.jpg"}
    ]
  },
  {
    "category": "Breakfast",
    "products": [
      {"id": 37, "name": "Idli 2 Pcs", "price": "$2.00", "offer": "10%", "org": "$2.20", "type": "veg", "img": "https://s3.ap-south-1.amazonaws.com/shopnowchat.com/Medium/MPRzocJOpYyWy_Idly2Pcs.jpg"},
      {"id": 38, "name": "Idiyappam 2 Pcs", "price": "$2.00", "offer": "8%", "org": "$2.20", "type": "veg", "img": "https://s3.ap-south-1.amazonaws.com/shopnowchat.com/Medium/MPRqKEwKqdmlt_Idiyappam.jpg"},
      {"id": 39, "name": "Pongal", "price": "$2.80", "offer": "15%", "org": "$3.30", "type": "veg", "img": "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/05/ven-pongal-recipe.jpg"},
      {"id": 40, "name": "Poori 2 Pcs", "price": "$3.00", "offer": "12%", "org": "$3.40", "type": "veg", "img": "https://t4.ftcdn.net/jpg/04/32/10/33/360_F_432103341_lY2BnhiB28t4k31EYt5SCigzMYkdEQ8p.jpg"},
      {"id": 41, "name": "Vadai", "price": "$1.00", "offer": "18%", "org": "$1.20", "type": "veg", "img": "https://www.shutterstock.com/image-photo/vada-medu-vadai-sambar-popular-260nw-400811605.jpg"},
      {"id": 42, "name": "Chappathi 2 Pcs", "price": "$3.00", "offer": "10%", "org": "$3.35", "type": "veg", "img": "https://static.toiimg.com/thumb/62915197.cms?width=325&height=243"},
      {"id": 43, "name": "Sambar Vadai", "price": "$2.00", "offer": "15%", "org": "$2.35", "type": "veg", "img": "https://t4.ftcdn.net/jpg/04/34/93/43/360_F_434934399_px1pXbG9U4NU5H10kCPkkwlKfOwREZVu.jpg"},
      {"id": 44, "name": "Curd Vadai", "price": "$2.00", "offer": "8%", "org": "$2.20", "type": "veg", "img": "https://i.pinimg.com/736x/34/ee/b1/34eeb18b7eab89637b3936e36e8abe5e.jpg"}
    ]
  },
  {
    "category": "Lunch Menu",
    "products": [
      {"id": 37, "name": "Vegetarian Set Meal", "price": "$8.00", "offer": "10%", "org": "$8.90", "type": "veg", "img": "https://t4.ftcdn.net/jpg/02/75/39/23/360_F_275392381_9upAWW5Rdsa4UE0CV6gRu2CwUETjzbKy.jpg"},
      {"id": 38, "name": "Banana Leaf South Indian Set Meal", "price": "$8.00", "offer": "15%", "org": "$9.40", "type": "veg", "img": "https://media.istockphoto.com/id/682477410/photo/banana-leaf-rice.jpg?s=612x612&w=0&k=20&c=Gygl85axt5B9ZSioULTNDcBNcHeDB5p1RzOr4zIT7O0="},
      {"id": 39, "name": "Chicken Set Meal", "price": "$9.00", "offer": "8%", "org": "$9.75", "type": "non-veg", "img": "https://shandongchinesekitchen.lk/wp-content/uploads/2020/05/Chicken-Set-Meal-Boneless-1.png"},
      {"id": 40, "name": "Mutton Set Meal", "price": "$10.00", "offer": "12%", "org": "$11.30", "type": "non-veg", "img": "https://img.freepik.com/premium-photo/mutton-thali-from-indian-cuisine-food-platter-consists-mutton-curry-lentils-jeera-rice-curd-onions-selective-focus_726363-766.jpg"},
      {"id": 41, "name": "Fish Set Meal", "price": "$8.00", "offer": "10%", "org": "$8.90", "type": "non-veg", "img": "https://c1.wallpaperflare.com/preview/840/295/578/blue-and-white-fish-set-meal-japanese-cuisine.jpg"},
      {"id": 42, "name": "Chicken Biriyan", "price": "$9.00", "offer": "15%", "org": "$10.60", "type": "non-veg", "img": "https://img.freepik.com/free-photo/gourmet-chicken-biryani-with-steamed-basmati-rice-generated-by-ai_188544-13480.jpg"},
      {"id": 43, "name": "Mutton Biriyan", "price": "$10.00", "offer": "8%", "org": "$10.85", "type": "non-veg", "img": "https://img.freepik.com/premium-photo/indian-mutton-biryani-prepared-basmati-rice-served-with-yogurt-dip-moody-background-selective-focus_466689-53059.jpg"},
      {"id": 44, "name": "Fish Biriyan", "price": "$9.00", "offer": "12%", "org": "$10.25", "type": "non-veg", "img": "https://media.istockphoto.com/id/1334383300/photo/fish-biryani-spicy-and-delicious-malabar-biryani-or-hydrabadi-biryani-dum-biriyani.jpg?s=612x612&w=0&k=20&c=NHYmMWB3wFbILekgO0d9gEfDcOXTi-c9q-E0yT3Y9lU="}
    ]
  },
  {
    "category": "Main Courses",
    "products": [
      {"id": 45, "name": "Chicken Masala", "price": "$5.00", "offer": "10%", "org": "$5.50", "type": "non-veg", "img": "https://media.istockphoto.com/id/579767430/photo/chicken-tikka-masala.jpg?s=612x612&w=0&k=20&c=EjeRH4r3w9qQ2WELp5qkqkUh1HbJJwRcFNNv1suOtvM="},
      {"id": 46, "name": "Pepper Chicken", "price": "$5.50", "offer": "15%", "org": "$6.50", "type": "non-veg", "img": "https://pikturenama.com/wp-content/uploads/2021/04/Low-res-Andhra-Pepper-Chicken-2.jpg"},
      {"id": 47, "name": "Chicken 65", "price": "$5.00", "offer": "8%", "org": "$5.45", "type": "non-veg", "img": "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/03/chicken-65-restaurant-style.jpg"},
      {"id": 48, "name": "Chicken Liver", "price": "$5.00", "offer": "12%", "org": "$5.70", "type": "non-veg", "img": "https://static.toiimg.com/thumb/60517055.cms?imgsize=389429&width=800&height=800"},
      {"id": 49, "name": "Mutton Mysore", "price": "$7.00", "offer": "10%", "org": "$7.75", "type": "non-veg", "img": "https://static.toiimg.com/thumb/53975304.cms?width=1200&height=900"},
      {"id": 50, "name": "Mutton Masala", "price": "$6.00", "offer": "15%", "org": "$7.05", "type": "non-veg", "img": "https://vaya.in/recipes/wp-content/uploads/2018/11/Mutton-Masala.jpg"},
      {"id": 51, "name": "Mutton Kudal", "price": "$5.00", "offer": "8%", "org": "$5.45", "type": "non-veg", "img": "https://lh3.googleusercontent.com/proxy/KOVBgm2KaI_uifTlH90nqTg3ptAbXq2_zhoiG41n20dOwmDiCDRDdoOUhg3MbHQIViDLLiSMylEssUJ8Bjr1sz0l1e6as0JMx-ACffWjRbRMfUWH3SIPy8QOwHC_r2b7w9YXF1UCjak58Q"},
      {"id": 52, "name": "Sotong", "price": "$5.00", "offer": "12%", "org": "$5.70", "type": "non-veg", "img": "https://cdn.pixabay.com/photo/2016/08/03/05/18/fried-1565908_1280.jpg"},
      {"id": 53, "name": "Prawn", "price": "$5.00", "offer": "10%", "org": "$5.50", "type": "non-veg", "img": "https://bigoven-res.cloudinary.com/image/upload/t_recipe-1280/prawn-masala-curry-4fe0fa52a54ee5360e670a30.jpg"},
      {"id": 54, "name": "Crab", "price": "$9.00", "offer": "15%", "org": "$10.60", "type": "non-veg", "img": "https://i.pinimg.com/originals/63/2e/c5/632ec51bcd5d258cb3586e1cbb96b45b.jpg"},
      {"id": 55, "name": "Indian Curry Fish Head Small", "price": "$18.00", "offer": "8%", "org": "$19.55", "type": "non-veg", "img": "https://www.mithila.net/wp-content/uploads/2014/06/Fish-Head-Curry.jpg"},
      {"id": 56, "name": "Indian Curry Fish Head Medium", "price": "$22.00", "offer": "12%", "org": "$24.95", "type": "non-veg", "img": "https://www.shutterstock.com/shutterstock/photos/2416124501/display_1500/stock-photo-fish-head-curry-served-in-clay-pot-isolated-on-wooden-table-top-view-of-indian-spicy-food-2416124501.jpg"},
      {"id": 57, "name": "Indian Curry Fish Head Large", "price": "$26.00", "offer": "10%", "org": "$28.80", "type": "non-veg", "img": "https://i.ndtvimg.com/i/2017-08/fish-head-620x350_620x350_41501656196.jpg"},
      {"id": 58, "name": "Egg Omelette", "price": "$2.00", "offer": "15%", "org": "$2.35", "type": "veg", "img": "https://cdn.pixabay.com/photo/2015/05/20/16/11/kitchen-775746_640.jpg"},
      {"id": 59, "name": "Egg Sambal", "price": "$1.50", "offer": "8%", "org": "$1.63", "type": "veg", "img": "https://img.taste.com.au/gmFoKPOU/w1200-h630-cfill/taste/2016/11/sambal-eggs-426-1.jpeg"}
    ]
  },
  {
    "category": "Goreng",
    "products": [
      {"id": 60, "name": "Nasi Goreng Egg", "price": "$5.50", "offer": "10%", "org": "$6.10", "type": "non-veg", "img": "https://slurrp.club/wp-content/uploads/2018/09/DSC_0129-750x500.jpg"},
      {"id": 61, "name": "Mee Goreng Normal", "price": "$5.00", "offer": "15%", "org": "$5.90", "type": "non-veg", "img": "https://www.joyousapron.com/wp-content/uploads/2021/03/Mee-Goreng-Sq-2.jpg"},
      {"id": 62, "name": "Mee Goreng Chicken", "price": "$6.00", "offer": "8%", "org": "$6.50", "type": "non-veg", "img": "https://img.taste.com.au/Esb-IEpx/taste/2016/11/chicken-mee-goreng-76698-1.jpeg"},
      {"id": 63, "name": "Mee Goreng Mutton", "price": "$7.00", "offer": "12%", "org": "$7.95", "type": "non-veg", "img": "https://the.fat.guide/wp-content/uploads/2021/10/Yusoff-Mee-Mutton-Soup-Briyani-Mee-Goreng-scaled.jpg"},
      {"id": 64, "name": "Bee Hoon Goreng", "price": "$5.00", "offer": "10%", "org": "$5.50", "type": "non-veg", "img": "https://christieathome.com/wp-content/uploads/2020/12/Indonesian-Bihun-Goreng-9-scaled.jpg"},
      {"id": 65, "name": "Mee Bee Hoon Jumbo", "price": "$5.50", "offer": "15%", "org": "$6.50", "type": "non-veg", "img": "https://www.shutterstock.com/image-photo/fried-bee-hoon-vegetables-famous-260nw-319535141.jpg"},
      {"id": 66, "name": "Nasi Goreng", "price": "$6.00", "offer": "8%", "org": "$6.50", "type": "non-veg", "img": "https://images.pexels.com/photos/8570300/pexels-photo-8570300.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
      {"id": 67, "name": "Kway Teow Goreng", "price": "$5.50", "offer": "12%", "org": "$6.25", "type": "non-veg", "img": "https://rasamalaysia.com/wp-content/uploads/2009/11/char-koay-teow-thumb.jpg"},
      {"id": 68, "name": "Nasi Goreng Chicken", "price": "$6.00", "offer": "10%", "org": "$6.65", "type": "non-veg", "img": "https://www.archanaskitchen.com/images/archanaskitchen/1-Author/sibyl-archanaskitchen.com/One_Pot_Chicken_Nasi_Goreng_Recipe__400.jpg"},
      {"id": 69, "name": "Nasi Goreng Ikan", "price": "$8.00", "offer": "15%", "org": "$9.40", "type": "non-veg", "img": "https://maribayaresort.com/wp-content/uploads/2021/12/Nasi-Goreng-Ikan-Asin.jpg"},
      {"id": 70, "name": "Nasi Goreng Veg", "price": "$6.00", "offer": "8%", "org": "$6.50", "type": "veg", "img": "https://realfood.tesco.com/media/images/303-vegetarian-vegetable-nasi-goreng-LH-e6ab7333-fb46-4c34-8a8e-177daabede6e-0-1400x919.jpg"},
      {"id": 71, "name": "Nasi Goreng Mutton", "price": "$5.00", "offer": "12%", "org": "$5.70", "type": "non-veg", "img": "https://www.cookmeindonesian.com/wp-content/uploads/2022/03/nasi-goreng-kambing-cover.jpg"},
      {"id": 72, "name": "Nasi Goreng Mushroom", "price": "$8.00", "offer": "10%", "org": "$8.90", "type": "veg", "img": "https://fast.ejazmine.com/wp-content/uploads/2021/09/Mushroom-Fried-Rice-800x600-1.jpg"},
      {"id": 73, "name": "Maggi Soup", "price": "$5.00", "offer": "15%", "org": "$5.90", "type": "veg", "img": "https://aartimadan.com/wp-content/uploads/2020/12/maggi-noodle-soup.jpg"}
    ]
  },
  {
    "category": "Drinks",
    "products": [
      {"id": 74, "name": "Tea", "price": "$1.00", "offer": "10%", "org": "$1.10", "type": "veg", "img": "https://e0.pxfuel.com/wallpapers/973/83/desktop-wallpaper-indian-tea-thumbnail.jpg"},
      {"id": 75, "name": "Tea O", "price": "$1.00", "offer": "15%", "org": "$1.20", "type": "veg", "img": "https://img.lovepik.com/photo/48014/4567.jpg_wh860.jpg"},
      {"id": 76, "name": "Masala Tea", "price": "$1.80", "offer": "8%", "org": "$1.95", "type": "veg", "img": "https://media.istockphoto.com/id/614533094/photo/indian-masala-chai-tea.jpg?s=612x612&w=0&k=20&c=0P-npS30JIBX0FA9csLyB0WYtkEU7gWkNE7nSnvXlSE="},
      {"id": 77, "name": "Tea O Lemi", "price": "$1.50", "offer": "12%", "org": "$1.70", "type": "veg", "img": "https://c4.wallpaperflare.com/wallpaper/736/514/447/tea-cup-lemon-mint-wallpaper-preview.jpg"},
      {"id": 78, "name": "Teh O Ginger", "price": "$1.50", "offer": "10%", "org": "$1.65", "type": "veg", "img": "https://img.freepik.com/premium-photo/glass-cup-hot-ginger-tea-with-ginger-rhizome-sliced-isolated-white-background_252965-22.jpg"},
      {"id": 79, "name": "Teh Susu Alia", "price": "$1.50", "offer": "15%", "org": "$1.75", "type": "veg", "img": "https://www.shutterstock.com/image-photo/tea-milk-popularly-known-teh-260nw-262866107.jpg"},
      {"id": 80, "name": "Halia O", "price": "$1.20", "offer": "12%", "org": "$1.35", "type": "veg", "img": "https://thumbs.dreamstime.com/b/black-tea-ginger-slice-known-as-teh-o-halia-malaysia-aroma-beverage-brown-cup-drink-food-fresh-glass-health-healthy-159131231.jpg"},
      {"id": 81, "name": "Ginger Tea", "price": "$1.50", "offer": "10%", "org": "$1.65", "type": "veg", "img": "https://files.selecthealth.cloud/api/public/content/221093-Tumeric_tea_blog_lg.jpg"},
      {"id": 82, "name": "Coffee", "price": "$1.00", "offer": "8%", "org": "$1.10", "type": "veg", "img": "https://img.freepik.com/free-photo/fresh-coffee-steams-wooden-table-close-up-generative-ai_188544-8923.jpg"},
      {"id": 83, "name": "Coffee O", "price": "$1.00", "offer": "12%", "org": "$1.15", "type": "veg", "img": "https://www.shutterstock.com/image-photo/coffee-cup-beans-on-white-600nw-111999368.jpg"},
      {"id": 84, "name": "Coffee Limau", "price": "$1.50", "offer": "15%", "org": "$1.75", "type": "veg", "img": "https://www.shutterstock.com/image-photo/add-lime-black-coffee-260nw-209672551.jpg"},
      {"id": 85, "name": "Bru", "price": "$1.60", "offer": "10%", "org": "$1.80", "type": "veg", "img": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6s-4z-V_OZr0wVHtDFLoQ99wf9SUGZQ0fJQ&usqp=CAU"},
      {"id": 86, "name": "Nescafe", "price": "$1.60", "offer": "15%", "org": "$1.90", "type": "veg", "img": "https://media.istockphoto.com/id/458130073/photo/nescafe-cup.jpg?s=612x612&w=0&k=20&c=smEbpGb46AHALgl-Ml3vkBGStN_YvOT-vj1zakBdspI="},
      {"id": 87, "name": "Neslo", "price": "$1.80", "offer": "8%", "org": "$1.95", "type": "veg", "img": "https://live.staticflickr.com/5605/15512753318_246d4decda_z.jpg"},
      {"id": 88, "name": "Coffee Ice", "price": "$1.80", "offer": "8%", "org": "$1.95", "type": "veg", "img": "https://www.allrecipes.com/thmb/Hqro0FNdnDEwDjrEoxhMfKdWfOY=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/21667-easy-iced-coffee-ddmfs-4x3-0093-7becf3932bd64ed7b594d46c02d0889f.jpg"},
      {"id": 89, "name": "Coffee O Ice", "price": "$1.50", "offer": "10%", "org": "$1.65", "type": "veg", "img": "https://www.shutterstock.com/image-photo/iced-coffee-tall-glass-cream-260nw-289454174.jpg"},
      {"id": 90, "name": "Milo", "price": "$1.60", "offer": "12%", "org": "$1.80", "type": "veg", "img": "https://www.shutterstock.com/image-photo/kuala-lumpur-malaysia-dec-9th-260nw-538715305.jpg"},
      {"id": 91, "name": "Horlicks", "price": "$1.80", "offer": "10%", "org": "$1.95", "type": "veg", "img": "https://4.imimg.com/data4/SJ/LL/MY-6759199/big-horlicks-mug-500x500.jpg"},
      {"id": 92, "name": "Bournvita", "price": "$2.00", "offer": "8%", "org": "$2.20", "type": "veg", "img": "https://eaglefox.in/wp-content/uploads/2020/07/Bournvita-Health-Drink-Jar-1-kg-a1-600x600.jpg"},
      {"id": 93, "name": "Boost", "price": "$2.00", "offer": "15%", "org": "$2.35", "type": "veg", "img": "https://i.ytimg.com/vi/SU32MkE0das/mqdefault.jpg"},
      {"id": 94, "name": "Hot Lime", "price": "$1.40", "offer": "10%", "org": "$1.55", "type": "veg", "img": "https://c4.wallpaperflare.com/wallpaper/600/75/631/ice-squirt-green-background-wallpaper-preview.jpg"},
      {"id": 95, "name": "Ice Milo", "price": "$2.20", "offer": "8%", "org": "$2.40", "type": "veg", "img": "https://i.pinimg.com/originals/f0/3b/f2/f03bf20f2553623c7e217c8c0856777a.png"},
      {"id": 96, "name": "Milo Dinosaur", "price": "$3.00", "offer": "12%", "org": "$3.40", "type": "veg", "img": "https://i0.wp.com/thenotsocreativecook.com/wp-content/uploads/2019/04/Milo-Dinosaur-1.jpg?fit=1642%2C1067"},
      {"id": 97, "name": "Limo Ice", "price": "$1.50", "offer": "10%", "org": "$1.65", "type": "veg", "img": "https://w0.peakpx.com/wallpaper/940/266/HD-wallpaper-lime-and-mint-mint-water-lime-ice.jpg"},
      {"id": 98, "name": "Fresh Lime Juice", "price": "$3.00", "offer": "15%", "org": "$3.50", "type": "veg", "img": "https://img.freepik.com/free-photo/lemonade-glass-with-lemons-herbs-side-view-white-plaster_176474-7389.jpg"},
      {"id": 99, "name": "Syrup Ice", "price": "$2.50", "offer": "8%", "org": "$2.75", "type": "veg", "img": "https://www.wallpaperflare.com/static/865/547/648/sweetness-paint-dishes-shaved-wallpaper.jpg"},
      {"id": 100, "name": "Syrup Bundong", "price": "$3.00", "offer": "10%", "org": "$3.35", "type": "veg", "img": "https://media.istockphoto.com/id/1167942461/photo/sirap-bandung-is-a-drink-popular-in-malaysia-singapore-and-brunei.jpg?s=612x612&w=0&k=20&c=GAvY_P8kgK4Hi47Gw8nlFe1XWkVd6mx9rFiwG7qubio="},
      {"id": 101, "name": "Can Drinks", "price": "$1.20", "offer": "15%", "org": "$1.40", "type": "veg", "img": "https://i.pinimg.com/originals/ff/2b/12/ff2b129f8e23bda1c77d13a8028269fe.jpg"},
      {"id": 102, "name": "Ice Kosong", "price": "$0.30", "offer": "8%", "org": "$0.33", "type": "veg", "img": "https://asiamd.com/wp-content/uploads/2021/11/iStock-520940172-ice-kosong-with-straw.jpg"},
      {"id": 103, "name": "Hot Water", "price": "$0.30", "offer": "10%", "org": "$0.33", "type": "veg", "img": "https://www.shutterstock.com/image-photo/pour-hot-water-night-260nw-2142733451.jpg"}
    ]
  }

]



  const [activeCategory, setActiveCategory] = useState(categories[0]);
  const productRef = useRef(null);



  const sortProducts = (category) => {
    let sortedProducts;

    if (sortBy === 'veg') {
      sortedProducts = category.products.slice().sort((a, b) => a.type.localeCompare(b.type));
    } else if (sortBy === 'price') {
      sortedProducts = category.products.slice().sort((a, b) => {
        const priceA = parseFloat(a.price);
        const priceB = parseFloat(b.price);
        return priceA - priceB;
      });
    } else {
      sortedProducts = category.products.slice();
    }

    return {
      ...category,
      products: sortedProducts,
    };
  };

  const sortedAllProducts = allProducts.map(category => sortProducts(category));


  const handleProductScroll = () => {
    const productContainer = productRef.current;
    if (productContainer) {
      const scrollTop = productContainer.scrollTop;
      const productsWithOffsets = allProducts.map((item, index) => ({
        ...item,
        offsetTop: productContainer.querySelectorAll(`[data-category="${item.category}"]`)[0].offsetTop,
      }));
  
      const currentCategory = productsWithOffsets.reduce((prev, curr) => (
        Math.abs(curr.offsetTop - scrollTop) < Math.abs(prev.offsetTop - scrollTop) ? curr : prev
      ));
  
      setActiveCategory(currentCategory.category);
    }
  };
  
  const handleCategoryClick = (category) => {
    const productContainer = productRef.current;
    if (productContainer) {
      const categorySection = productContainer.querySelector(`[data-category="${category}"]`);
      
      if (categorySection) {
        const categoryOffset = categorySection.offsetTop - 470;
        productContainer.scrollTo({ top: categoryOffset, behavior: 'smooth' });
      }
    }
  };
  
  
  
  return (
    <Grid container justifyContent='space-between' >
      <Grid item md={2.8}  >
        <Paper className='deskcat' style={{ height: '100%', overflowY: 'auto' }}>
        {categories.map(category => (
          <Typography 
            key={category}
            className={`category-text ${activeCategory === category ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </Typography>
        ))}
        </Paper>

        <Paper className='mobcat' style={{ height: '100%', overflowY: 'auto' }}>
        {categories.map(category => (
          <Typography 
            key={category}
            className={`category-text ${activeCategory === category ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </Typography>
        ))}
        </Paper>
      </Grid>
      <Grid item md={9} className='productcont' >
        <Paper
           style={{
            height: '100%',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            WebkitOverflowScrolling: 'touch',
          }}
          ref={productRef}
          onScroll={handleProductScroll}
        >
          {sortedAllProducts && sortedAllProducts.length && sortedAllProducts.map((item, index) => (
            <>
          <Typography data-category={item.category} className='catName'>{item.category}</Typography>
          <Grid container  spacing={2}>
            {item.products.map((product, index) => (
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4} className="image-hover-effect" key={index} data-product-index={index}>
                <Card sx={{ maxWidth: 345 }}>
                {product.type === 'veg' ? (
                  <>
                    <Grid container justifyContent='space-between'  sx={{maxWidth:'70px',padding:'10px'}}>
                      <Grid item>
                        <FiberManualRecordIcon sx={{fontSize:'10px' , border:'1px solid #2ebd59' , padding:'2px' , color:'#2ebd59'}} />
                      </Grid>
                      <Grid item>
                        <Typography sx={{fontSize:'14px'}}>Veg</Typography>
                      </Grid>
                    </Grid>
                  </>
                ):(
                  <Grid container justifyContent='space-between'  sx={{maxWidth:'110px',padding:'10px'}}>
                  <Grid item>
                    <FiberManualRecordIcon sx={{fontSize:'10px' , border:'1px solid #d0021b' , padding:'2px' , color:'#d0021b'}} />
                  </Grid>
                  <Grid item>
                    <Typography sx={{fontSize:'14px'}}>Non - Veg</Typography>
                  </Grid>
                </Grid>
                )}
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={product.img  || "https://d3gy1em549lxx2.cloudfront.net/8f885d75-d642-4db3-89ce-04cfc428db37.jpg"}
                      alt="green iguana"
                      className='prodimg'
                    />
                    <CardContent >
                      <Typography  variant="body1" color="text.secondary">
                       {product.name}
                      </Typography>
                      <Grid container justifyContent='space-between' sx={{padding:'10px 0px'}}>
                        <Grid item>
                          <Grid container direction='column' >
                            <Grid item sx={{display:'flex' , flexDirection:'row'}}>
                              <Typography sx={{fontWeight:'bold' , fontSize:'1.2rem'}}>{product.price || '100'}</Typography>
                              {/* <Typography sx={{fontSize:'1.2rem', textDecoration:'line-through' , paddingLeft:'10px'}}>₹{product.org || '-'}</Typography> */}
                            </Grid>
                            {/* <Grid item>
                              <Typography>{product.offer}% OFF</Typography>
                            </Grid> */}
                          </Grid>
                        </Grid>
                        {/* <Grid item>
                           <button className="button-5">Add</button>
                        </Grid> */}
                        <Grid item>
                          {/* <Typography>{product.offer}% OFF</Typography> */}
                        </Grid>
                      </Grid>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
          </>
          ))}
        </Paper>
      </Grid>
    </Grid>
    
  );
};

export default ProductPage;