import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './pages/Homepage';
import About from './pages/About';
import Navbar from './components/homepage/Navbar';
import Footer from './components/homepage/Footer/Footer';
import Products from './components/homepage/Products';
import './App.css';

function App() {
  return (
    <BrowserRouter basename="/">
      {/* <AuthProvider>
        <Header /> */}
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<About />} />
          <Route path="/menu" element={<Products />} />
          <Route path="*" element={<div>404</div>} />
        </Routes>
        <Footer />
        {/* <Footer />
      </AuthProvider> */}
    </BrowserRouter>
  );
}

export default App;