import React from 'react';
import { Grid, Typography  } from '@mui/material';
import CarouselComponent from '../components/homepage/carousel';
import '../App.css';
import b1 from '../assets/b1.png';
import b2 from '../assets/b2.png';
import { SocialIcon } from 'react-social-icons';


const Home = () => {


  return (
    <>
       <Grid>
        
        <Grid sx={{height:'50px'}}></Grid>
        <Grid sx={{padding:'50px 0px'}}>
          <CarouselComponent />
        </Grid>
        <Grid>
          {/* <Products /> */}
          <Grid container justifyContent='space-between' sx={{width:'80%' , margin:'0 auto'}}>
            <Grid item md={6} pt={3} pb={3}>
              <Typography className='typo1'>About Us :</Typography>
              <Typography className='typo2'>At Indian Restaurant Pte Ltd, we take pride in bringing the diverse and vibrant flavors of India to Singapore. Our chefs are dedicated to crafting dishes that showcase the true essence of Indian culinary traditions. From North Indian classics to South Indian delights, our menu is a celebration of the country's culinary heritage.
              </Typography>
            </Grid>
            <Grid item md={5}>
              <img  style={{borderRadius: '50px 15px'}} src={b1} width='100%' alt='b1' />
            </Grid>
          </Grid>
          <Grid container justifyContent='center' textAlign='center' sx={{width:'80%' , margin:'50px auto'}}>
            <Typography className='typo1'>Our Menu :</Typography>
            <Typography className='typo2'>Explore our extensive menu that caters to all tastes and preferences. Indulge in the aromatic spices of our biryanis, savor the creamy richness of our curries, and experience the crispy goodness of our dosas. Whether you're a fan of vegetarian specialties or a meat lover, our menu has something for everyone.</Typography>
          </Grid>
          <Grid container sx={{width:'80%' , margin:'30px auto' , justifyContent:{xs:'center' , sm:'center' , md:'space-between'} }}>
            <Grid item md={3.5} mt={2}>
              <div class="card first">
                  <div class="card__body">
                      <Typography className='card-typo'>Butter Chicken</Typography>
                  </div>
                  <span>Succulent pieces of chicken simmered in a rich and creamy tomato-based gravy, a true North Indian delight.</span>
              </div>
            </Grid>
            <Grid item md={3.5} mt={2}>
            <div class="card second">
                <div class="card__body">
                    <Typography className='card-typo' minWidth='33vh'>Masala Dosa</Typography>
                </div>
                <span>A South Indian classic featuring a thin, crispy rice crepe filled with a spiced potato mixture.</span>
            </div>
            </Grid>
            <Grid item md={3.5} mt={2}>
            <div class="card third">
                <div class="card__body">
                    <Typography className='card-typo' minWidth='33vh'>Paneer Tikka</Typography>
                </div>
                <span> Marinated cubes of paneer grilled to perfection, bursting with flavors and served with mint chutney.</span>
            </div>
            </Grid>
          </Grid>
          <Grid container justifyContent='space-between' sx={{width:'80%' , margin:'60px auto'}}>
            <Grid item md={5}>
              <img style={{borderRadius: '15px 50px'}} src={b2} width='100%' alt='b1' />
            </Grid>
            <Grid item md={6}>
              <Typography className='typo1'>Ambiance :</Typography>
              <Typography className='typo2'>Step into our restaurant and be transported to the lively streets of India. Our warm and welcoming ambiance is designed to enhance your dining experience, making you feel right at home.</Typography>
              <Typography style={{marginTop:'30px'}} className='typo1'>Catering Services :</Typography>
              <Typography className='typo2'>Planning a special event? Let Indian Restaurant Pte Ltd take care of your catering needs. We offer a range of catering services that bring the essence of Indian cuisine to your gatherings, be it weddings, corporate events, or private parties.</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent='center' textAlign='center' sx={{width:'80%' , margin:'50px auto'}}>
            {/* <Typography className='typo3'>Indulge your senses at The Grill Chill - Because every meal is an adventure!</Typography> */}
          </Grid>
        </Grid>
        <div className="floating-icon">
          <SocialIcon url="https://wa.me/+6598741771?text=Hi" network="whatsapp" target="_blank" />
        </div>
       </Grid>
       
    </>
  );
};

export default Home;